<template>
    <div class="purse-balance" v-if="null != purse">
        <div>
            <h4><b>Баланс:</b> {{purse.balance}}</h4>
            <div class="form-inline mb-2">
                <div class="form-group">
                    <label>Добавить на баланс:</label>
                    <input v-model="amount" class="form-control mr-2 ml-2" />
                    <button class="btn btn-primary btn-sm"
                            v-on:click="updateBalance">Добавить</button>
                </div>
            </div>
        </div>

        <div class="purse-correction">
            <div class="form-inline mb-2">
                <div class="form-group">
                    <label>Корректировать баланс:</label>
                    <input v-model="correctAmount" class="form-control mr-2 ml-2"
                           :disabled="!correctEnabled"/>
                    <button class="btn btn-primary btn-sm"
                            :disabled="!correctEnabled"
                            v-on:click="correctPurseBalance">Корректировать</button>
                    <input v-model="correctEnabled" class="form-control mr-2 ml-2" type="checkbox"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import PurseService from "../services/PurseService";

    export default {
        name: "PurseBalance",
        props: {
            userId: Number
        },
        data() {
            return {
                purse: null,
                amount: null,

                correctAmount: null,
                correctEnabled: false
            }
        },

        methods: {
            updateBalance() {
                if (null != this.amount && this.amount !=0){
                    PurseService.updatePurseBalance(this.purse.id, this.amount)
                    .then((response) => {
                        this.purse = response.data;
                        this.amount=null;
                        this.$emit("change")
                    })
                }
            }
            ,correctPurseBalance() {
                if (null != this.correctAmount && this.correctAmount !=0){
                    PurseService.correctPurseBalance(this.purse.id, this.correctAmount)
                    .then((response) => {
                        this.purse = response.data;
                        this.correctAmount=null;
                        this.$emit("change")
                    })
                }
            }
        },
        mounted() {
            let self = this;
            PurseService.getUserPurse(this.userId)
            .then((response) => {
                self.purse = response.data;
            })
            .catch((error) => {
                alert(error.response)
            })
        }
    }
</script>
