<template>
    <div>
    <div class="row">
        <div class="col-6">
            <h3>Ставки пользователя</h3>
        </div>
        <div class="col-6">
            <select v-model="selectedBlogId">
                <option :value="null">Блог</option>
                <option v-for="blog in blogs" v-bind:key="blog.id" v-bind:value="blog.id">
                    {{blog.title}}
                </option>
            </select>
        </div>
    </div>

    <div class="row">
        <div class="col-12 users-list">
            <table class="table">
                <tr>
                    <th>ID</th>
                    <th></th>
                    <th>Матч</th>
                    <th>Тип</th>
                    <th>Ставка</th>
                    <th>Коммисия</th>
                    <th>Профит</th>
                    <th>Статус</th>
                    <th>Отчисление</th>
                </tr>
                <tr v-for="ub in bets" v-bind:key="ub.id">
                    <td>{{ub.id}}<br/><span v-if="!userId">{{ub.user.username}}</span></td>
                    <td><a :href="'/orderbets/' + ub.orderBetId">Заказ #{{ub.orderBetId}}</a></td>
                    <td>
                        {{ub.event.home}} - {{ub.event.away}}<br/>
                        {{ new Date(ub.event.playAt) | moment("YYYY-MM-DD HH:mm") }}<br/>
                        Блог {{ub.blog.title}}
                    </td>
                    <td>
                        <span v-if="ub.betName=='special'">{{ub.description}}</span>
                        <span v-else>{{ub.betName}}</span>
                        <br/>{{ub.period}}</td>
                    <td>{{ub.stake}} / {{ub.price}}</td>
                    <td>{{ub.planCommission}}</td>
                    <td>{{ub.profit}}</td>
                    <td>{{ub.status}}</td>
                    <td>
                        <template v-if="ub.deductionRegistry != null">
                            Reg: {{ub.deductionRegistry.registry}}<br/>
                            Отч.: {{ub.deductionRegistry.deduction}}<br/>
                            Время: {{ new Date(ub.deductionRegistry.createdAt) | moment("YYYY-MM-DD HH:mm") }}<br/>
                        </template>
                        - ({{ub.deduction}}) -
                    </td>
                </tr>
            </table>
            <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-on:change="selectPage"
                    aria-controls="users-list"
            ></b-pagination>
        </div>
    </div>
    </div>
</template>

<script>
import UserBetService from "@/services/UserBetService";

export default {
    name: "UserBetsList",
    props: {
        userId: Number,
        orderBetId: Number
    },
    data(){
        return {
            currentPage: 1,
            totalRows: 0,
            perPage:1,
            bets: [],

            blogs: [],
            selectedBlogId: null
        }
    },
    methods: {
        selectPage(page) {
            this.currentPage=page;
            this.loadBets();
        },
        loadBets(){
            var self = this;
            var act = null;
            if (self.userId > 0) {
                act = UserBetService.getUserBetsList(self.userId, self.currentPage-1, self.selectedBlogId)
            } else if (self.orderBetId > 0) {
                act = UserBetService.getOrderBetsByOrder(self.orderBetId, self.currentPage-1)
            }
            if (act !== null) {
                act.then((response) => {
                    self.bets = response.data.content;

                    self.currentPage = response.data.number+1;
                    self.totalRows = response.data.totalElements;
                    self.perPage = response.data.size;
                })
            }
        },
        loadBlogs() {
            var self = this;
            UserBetService.getUserBlogs(this.userId)
            .then((response) => {
                self.blogs = response.data;
            })
        }
    },
    watch: {
        selectedBlogId: function () {
            this.loadBets();
        }
    },

    mounted() {
        this.loadBets();
        this.loadBlogs();
    }
}
</script>