<template>
  <div class="purse-log-list">
    <h2>Purse log</h2>
    <div>
      <div style="width: 250px; display: inline-block">
        <b-form-select v-model="selectedPurseReason"
                       :options="purseReasons"
                       aria-placeholder="sss"
                       class="mb-2"></b-form-select>
      </div>
    </div>

    <table class="table purse-log-list-table">
      <tr v-for="item in items" v-bind:key="item.id">
        <td>{{ new Date(item.createdAt) | moment("YYYY-MM-DD HH:mm") }}</td>
        <td>{{ item.before }}</td>
        <td>{{ item.after }}</td>
        <td>
                    <span v-bind:class="{'text-success':isAddDiffClass(item), 'text-danger':isSubDiffClass(item)}">
                        <span v-if="isAddDiffClass(item)">+</span>
                        <span v-else-if="isSubDiffClass(item)">-</span>{{ item.diff }}
                    </span>
        </td>
        <td>{{ item.description }}</td>
      </tr>
    </table>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        v-on:change="selectPage"
        aria-controls="purse-log-list"
    ></b-pagination>
  </div>
</template>

<script>
import PurseService from "../services/PurseService";

export default {
  name: "PurseLogList",
  props: {
    userId: Number
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 1,
      items: [],
      purseReasons: [
        {value: null, text:'Тип платежа'},
        {value: 30, text:'Пополнене FreeKasa'},
        {value: 31, text:'Оплата приложения'},
        {value: 33, text:'Вывод'},
        {value: 2, text:'Add Commission'},
      ],
      selectedPurseReason: null,
    }
  },
  methods: {
    selectPage(page) {
      this.currentPage = page;
      this.loadList();
    },
    loadList() {
      var self = this;
      PurseService.getPurseLog(this.userId, this.selectedPurseReason, self.currentPage - 1)
          .then((response) => {
            self.items = response.data.content;

            self.currentPage = response.data.number + 1;
            self.rows = response.data.totalElements;
            self.perPage = response.data.size;
          })
    },
    isAddDiffClass(log) {
      return (log.after > log.before)
    },

    isSubDiffClass(log) {
      return (log.after < log.before)
    }
  },
  watch: {
    selectedPurseReason: function() {
      this.loadList();
    }
  },
  mounted() {
    this.loadList()
  }
}
</script>

<style scoped>

</style>