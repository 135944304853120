<template>
  <div>
    <div class="row" v-if="subscriptionsConfig != null">
      <div class="col-6">
        <table>
          <tr>
            <td>Активна</td>
            <td>
              <span v-if="subscriptionsConfig.active">Да</span>
              <span v-else>Нет</span>
            </td>
          </tr>
          <tr>
            <td>Сумма ставки</td>
            <td>$ {{subscriptionsConfig.stakeAmount}}</td>
          </tr>
          <tr>
            <td>Макс. коэф.</td>
            <td>{{subscriptionsConfig.maxPrice}}</td>
          </tr>
        </table>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-6">
        <h3>Активные подписки</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12 users-list">
        <table class="table">
          <tr>
            <th>ID</th>
            <th>Блог</th>
            <th>План</th>
            <th>Коммиссия плана</th>
            <th>Сумма ставки</th>
            <th>Макс. коэф.</th>
            <th>Приоритет</th>
          </tr>
          <tr v-for="subscription in subscriptions" v-bind:key="subscription.id">
            <td>{{subscription.id}}</td>
            <td>{{subscription.blog.title}}</td>
            <td>
              {{subscription.betPlanInfo.name}}
            </td>
            <td>
              {{subscription.betPlanInfo.percent}}%
            </td>
            <td>
              $ {{subscription.wantStake}}
            </td>
            <td>
              {{subscription.maxPrice}}
            </td>
            <td>
              <span v-if="subscription.priority">on</span>
            </td>
          </tr>
        </table>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            v-on:change="selectPage"
            aria-controls="users-list"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import UserService from "@/services/UserService";

export default {
  name: "UserActiveSubscriptions",
  props: {
    userId: Number,
    orderBetId: Number
  },
  data(){
    return {
      currentPage: 1,
      totalRows: 0,
      perPage:1,
      bets: [],

      subscriptions: [],
      subscriptionsConfig: null
    }
  },
  methods: {
    selectPage(page) {
      this.currentPage=page;
      this.loadActiveSubscriptions();
    },
    loadActiveSubscriptions(){
      var self = this;
      var act = null;
      if (self.userId > 0) {
        act = UserService.getUserActiveSubscriptions(self.userId, self.currentPage-1)
      }
      if (act !== null) {
        act.then((response) => {
          self.subscriptions = response.data.content;

          self.currentPage = response.data.number+1;
          self.totalRows = response.data.totalElements;
          self.perPage = response.data.size;
        })
      }
    },

    loadSubscriptionsConfig() {
      var self = this;
      UserService.getUserSubscriptionsConfig(self.userId)
      .then((response) => {
        self.subscriptionsConfig = response.data;
      })

    }
  },
  mounted() {
    this.loadActiveSubscriptions();
    this.loadSubscriptionsConfig();
  }
}
</script>