<template>
  <div class="user-settings border-dark border-primary">
    <h4><b>Настройки:</b> </h4>
    <div>
      <div class="mb-2">
        <table>
          <tr>
            <td>Максимальная сумма ставки:</td>
            <td>
              <input type="text" class="form-control" v-model="maxStake"/>
            </td>
          </tr>
          <tr>
            <td>VIP:</td>
            <td>
              <input type="checkbox" v-model="vip"/>
            </td>
          </tr>
        </table>
      </div>
      <div class="form-group">
        <button v-on:click="saveSettings"
                class="btn btn-primary btn-sm">Сохранить настройки</button>
      </div>

    </div>
  </div>
</template>

<script>

import UserService from "../services/UserService";

export default {
  name: "UserSettings",
  props: {
    userId: Number
  },
  data() {
    return {
      maxStake: null,
      vip: false
    }
  },
  methods: {
    saveSettings() {
      var self = this;
      UserService.saveUserSettings({
        userId: self.userId,
        maxStake: self.maxStake,
        vip: self.vip
      })
      .then(() => {
        alert("Настройки сохранены")
      })
    }
  },
  mounted() {
    var self = this;
    UserService.getUserSettings(this.userId)
    .then((response) => {
      self.maxStake = response.data.maxStake;
      self.vip = response.data.vip;
    })
  }
}
</script>
