<template>
    <div v-if="null != user" class="user-single">
        <div class="row">
            <div class="col-12">
                <h2>#{{user.id}} {{user.username}} ({{user.role}})</h2>
                <hr/>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
              <PurseBalance :user-id="user.id" v-on:change="balanceChanged"></PurseBalance>
              <hr/>
              <UserSettings :user-id="user.id"></UserSettings>
            </div>
            <div class="col-6"  v-if="null != purseControl">
               <PurseControl v-bind:purse-control="purseControl"></PurseControl>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <b-tabs content-class="mt-3" lazy>
                    <b-tab title="Ставки" active>
                        <div class="row">
                            <div class="col-12">
                                <button v-on:click="exportCsv" class="btn btn-success">Экспорт ставок в CSV</button>
                            </div>
                        </div>
                        <UserBetsList :user-id="user.id"></UserBetsList>
                    </b-tab>
                    <b-tab title="Логи кошелька">
                        <PurseLogList :user-id="user.id" :key="logsRefreshKey"></PurseLogList>
                    </b-tab>
                  <b-tab title="Активные подписки">
                    <UserActiveSubscriptions :user-id="user.id"></UserActiveSubscriptions>
                  </b-tab>
                  <b-tab title="Подписчики">
                    <UserSubscribers :user-id="user.id"></UserSubscribers>
                  </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from "../services/UserService";
    import ExportService from "../services/ExportService";
    import PurseService from "../services/PurseService";

    import PurseBalance from "../components/PurseBalance";
    import PurseLogList from "../components/PurseLogList";
    import UserBetsList from "../components/UserBetsList";
    import UserActiveSubscriptions from "../components/UserActiveSubscriptions";
    import UserSubscribers from "../components/UserSubscribers";
    import PurseControl from "../components/PurseControl";
    import UserSettings from "../components/UserSettings";

    export default {
        name: "UsersSingle",
        components: {PurseControl, PurseLogList, PurseBalance, UserBetsList, UserSettings, UserActiveSubscriptions, UserSubscribers},
        data() {
            return {
                user: null,
                logsRefreshKey: 0,
                purseControl: null
            }
        },
        mounted() {
            let self = this;
            if (null != this.$route.params.id) {
                let userId = this.$route.params.id;
                UserService.getUser(userId)
                    .then((response) => {
                        self.user = response.data
                    });

                this.loadControl()

            }
        },
        methods: {
            balanceChanged() {
                this.logsRefreshKey += 1;
                this.loadControl()
            },
            exportCsv() {
                ExportService.exportCsv(this.$route.params.id);
            },
            loadControl() {
                let self = this;
                if (null != this.$route.params.id) {
                    let userId = this.$route.params.id;
                    PurseService.getPurseControl(userId)
                        .then((response) => {
                            self.purseControl = response.data
                        });
                }
            }

        }
    }
</script>
